body,
html * {
	margin: 0;
	font-family: 'HM Group Ampersand', sans-serif !important;
}

::-webkit-scrollbar {
	width: 5px;
}

::-webkit-scrollbar-track {
	background: #e0e0e0;
}

::-webkit-scrollbar-thumb {
	background: #888;
}

::-webkit-scrollbar-track:hover {
	background: #555;
}
